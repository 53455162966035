import React from 'react'
import Inview from 'components/Inview'
import * as styles from './styles.module.scss'

const Component = ({ content }) => {
  return (
    <Inview className={`${styles.component} fade-in up`}>
      <h5>{content.title}</h5>
    </Inview>
  )
}

export default Component
