import React from 'react'
import Inview from 'components/Inview'
import TitleLine from 'components/TitleLine'
import MarkdownWrap from 'components/MarkdownWrap'
import * as styles from './styles.module.scss'

const Block = ({ content, isNoHeader, isBoldFirstTd }) => {
  const { table, title } = content
  const noHeaderClass = isNoHeader ? 'no-header' : ''
  const boldFirstTdClass = isBoldFirstTd ? 'bold-first-td' : ''

  return (
    <div className='block pad-v-standard'>
      <Inview className='container fade-in up grid-24'>
        <div className={styles.tableWrap}>
          {title && <TitleLine content={{ title }} />}
          <MarkdownWrap
            className={`${noHeaderClass} ${boldFirstTdClass}`}
            body={table}
          />
        </div>
      </Inview>
    </div>
  )
}

export default Block
