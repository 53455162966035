import React from 'react'
import LineLink from 'components/LineLink'
import Inview from 'components/Inview'
import * as styles from './styles.module.scss'

const Block = ({ content }) => {
  const { title, link } = content
  return (
    <Inview className='block pad-v-standard fade-in up'>
      <div className='container grid-24'>
        <div className={styles.inner}>
          <h6>
            <LineLink
              content={{
                to: link,
                text: title,
                linkType: 'internal',
              }}
              partiallyActive={false}
            />
          </h6>
        </div>
      </div>
    </Inview>
  )
}

export default Block
