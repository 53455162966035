import React from 'react'
import BannerProduct from 'blocks/BannerProduct/template'
import TableOnly from 'blocks/TableOnly/template'
import ProductsGrid from 'blocks/_dynamic/ProductsGrid/template'
import FooterBack from 'blocks/FooterBack/template'
import HeaderVersionWrap from 'components/HeaderVersionWrap'
import Footer from 'components/Footer'

const ContentType = ({ pageId, content, locale, layoutProps }) => {
  if (!locale) locale = 'en'

  const strings = {
    en: {
      tableTitle: 'Product Specifications',
      moreTitle1: 'More ',
      moreTitle2: ' Products',
      // footerBackTitle: 'View All Products',
      footerBackTitle: 'View All',
    },
    zh: {
      tableTitle: '产品规格',
      moreTitle1: '更多',
      moreTitle2: '产品',
      // footerBackTitle: '查看全部产品',
      footerBackTitle: '查看全部',
    },
  }

  const pageContent = {
    bannerProduct: {
      breadCrumb: [
        {
          title: content.thisPage.title,
          link: `/${content.thisPage.slug}`,
        },
        {
          title: content.thisProductCat.title,
          link: `/${content.thisPage.slug}/${content.thisProductCat.slug}`,
        },
      ],
      title: content.title,
      dimensions: content.dimensions,
      image: content.image,
    },
    tableOnly: {
      title: strings[locale].tableTitle,
      table: content.table,
    },
    productsGrid: {
      title: strings[locale].moreTitle1 + content.thisProductCat.title,
      //  + strings[locale].moreTitle2,
      productCatSlug: content.productCatSlug,
      removeSlug: content.slug,
    },
    footerBack: {
      title: strings[locale].footerBackTitle,
      link: `/${content.thisPage.slug}/${content.thisProductCat.slug}`,
    },
  }

  return (
    <div className='page'>
      <div className='block theme bg-light'>
        <HeaderVersionWrap layoutProps={layoutProps} isDark={false}>
          <BannerProduct content={pageContent.bannerProduct} />
        </HeaderVersionWrap>
      </div>
      <div className='block theme bg-shade'>
        <HeaderVersionWrap layoutProps={layoutProps} isDark={false}>
          {content.table && (
            <TableOnly
              content={pageContent.tableOnly}
              isNoHeader={true}
              isBoldFirstTd={true}
            />
          )}
        </HeaderVersionWrap>
      </div>
      <div className='block theme bg-light'>
        <HeaderVersionWrap layoutProps={layoutProps} isDark={false}>
          <ProductsGrid content={pageContent.productsGrid} locale={locale} />
          <FooterBack content={pageContent.footerBack} />
        </HeaderVersionWrap>
      </div>
      <Footer locale={locale} />
    </div>
  )
}

export default ContentType
