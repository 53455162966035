import React from 'react'
import ImageWrap from 'components/ImageWrap'
import LinkWrap from 'components/LinkWrap'
import Inview from 'components/Inview'
import TriangleRightSvg from 'assets/svg/ui/triangle-right.svg'
import * as styles from './styles.module.scss'

const Block = ({ content }) => {
  const { breadCrumb, title, dimensions, image } = content
  return (
    <div className={`block pad-v-standard first-add-v-pad `}>
      <Inview className='container fade-in up grid-24'>
        <div className={styles.inner}>
          <div className={styles.top}>
            <div className={styles.text}>
              <h5>
                <LinkWrap to={breadCrumb[0].link}>
                  {breadCrumb[0].title}
                </LinkWrap>
                <TriangleRightSvg />
                <LinkWrap to={breadCrumb[1].link}>
                  {breadCrumb[1].title}
                </LinkWrap>
              </h5>
              <h1>{title}</h1>
            </div>
            {image && (
              <div className={styles.image}>
                <ImageWrap image={image} aspectRatio={1} />
              </div>
            )}
          </div>
          <div className={styles.bottom}>
            <h5 className='smaller'>{dimensions}</h5>
          </div>
        </div>
      </Inview>
    </div>
  )
}

export default Block
