import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Grid2Items from 'components/Grid2Items'
import TitleLine from 'components/TitleLine'
import * as styles from './styles.module.scss'

const Block = ({ content, data, locale, pageContext }) => {
  const products = data?.products?.nodes
  const productsLocale = products?.map((item) => item.frontmatter[locale])
  let productsLocaleFiltered = productsLocale.filter(
    (product) => product.productCatSlug === content.productCatSlug
  )
  if (content.removeSlug) {
    productsLocaleFiltered = productsLocaleFiltered.filter(
      (product) => product.slug !== content.removeSlug
    )
  }

  const grid = {
    items: productsLocaleFiltered.map((item) => {
      return {
        image: item.image,
        title: item.title,
        subtitle: item.dimensions,
        link: `/products/${item.productCatSlug}/${item.slug}`,
      }
    }),
  }

  return (
    <>
      {grid.items.length > 0 && (
        <div className='block pad-v-standard'>
          <div className='container grid-24'>
            <div className={styles.inner}>
              <TitleLine content={{ title: content.title }} />
              <Grid2Items content={grid} />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default function Data({ content, locale, pageContext }) {
  return (
    <StaticQuery
      query={graphql`
        query {
          products: allMarkdownRemark(
            filter: { frontmatter: { en: { templateKey: { eq: "product" } } } }
            sort: { fields: frontmatter___en___title, order: ASC }
          ) {
            nodes {
              frontmatter {
                en {
                  title
                  dimensions
                  slug
                  productCatSlug
                  image {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
                zh {
                  title
                  dimensions
                  slug
                  productCatSlug
                  image {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => (
        <Block
          content={content}
          data={data}
          locale={locale}
          pageContext={pageContext}
        />
      )}
    />
  )
}
