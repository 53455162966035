import React from 'react'
import { graphql } from 'gatsby'
import Seo from 'components/Seo'
import PageTemplate from './template'

const ContentType = (props) => {
  const { data, pageContext, layoutProps } = props
  const { locale } = pageContext
  const { page, productPage, productCats } = data
  let content = page.frontmatter[locale]
  content.thisPage = productPage.frontmatter[locale]

  const productCatsLocale = productCats?.nodes?.map(
    (item) => item.frontmatter[locale]
  )
  content.thisProductCat = productCatsLocale.find(
    (productCat) => productCat.slug === content.productCatSlug
  )

  content.seo = {
    title: `${content.thisPage.title} - ${content.thisProductCat.title} - ${content.title}`,
  }

  return (
    <>
      <Seo content={content} locale={locale} />
      <PageTemplate
        pageId={page.id}
        content={content}
        locale={locale}
        layoutProps={layoutProps}
      />
    </>
  )
}

export default ContentType

export const pageQuery = graphql`
  query ($id: String!) {
    productPage: markdownRemark(
      frontmatter: { en: { slug: { eq: "products" } } }
    ) {
      id
      frontmatter {
        en {
          title
          slug
        }
        zh {
          title
          slug
        }
      }
    }
    page: markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        en {
          slug
          title
          productCatSlug
          dimensions
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
          table
          seo {
            title
            description
          }
        }
        zh {
          slug
          title
          productCatSlug
          dimensions
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
          table
          seo {
            title
            description
          }
        }
      }
    }
    productCats: allMarkdownRemark(
      filter: { frontmatter: { en: { templateKey: { eq: "productCat" } } } }
      sort: { fields: frontmatter___en___order, order: ASC }
    ) {
      nodes {
        frontmatter {
          en {
            title
            slug
          }
          zh {
            title
            slug
          }
        }
      }
    }
  }
`
